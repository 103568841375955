import { Suspense, type ReactNode } from "react";
import { ReportingErrorBoundary } from "../../dev/ReportingErrorBoundary.tsx";

export interface FallbackRenderProps {
    isDataError: boolean;
    items: any[];
    isLoading: boolean;
}

export function WSelectWrapper({
    fallback,
    children,
}: {
    fallback: (props: FallbackRenderProps) => ReactNode;
    children: ReactNode;
}) {
    return (
        <ReportingErrorBoundary ErrorComponent={() => fallback({ items: [], isDataError: true, isLoading: false })}>
            <Suspense fallback={fallback({ items: [], isLoading: true, isDataError: false })}>{children}</Suspense>
        </ReportingErrorBoundary>
    );
}
